<template>
  <header>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Neue Datei laden
          </v-card-title>
          <v-card-text>Möchtest du wirklich eine neue Datei auswählen? Alle nicht gespeicherten Änderungen gehen verloren</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
              color="primary"
              text              
              @click="unloadHtmlFile"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    
    <v-app-bar      
      dense  
      dark>
       <v-toolbar-title 
          v-if="showUnloadFileIcon"       
          @click="rUSure">      
          <v-icon color="blue">
                mdi-close
              </v-icon>               
        </v-toolbar-title>              
        <v-spacer/>
        <v-toolbar-title          
            text>            
              5code.notes            
        </v-toolbar-title>  
        <v-spacer/>
        <v-toolbar-title
          @click ="$router.push({ name: 'help' }).catch(()=>{})"            
            >
              <v-icon color="blue">
                mdi-help
              </v-icon>               
        </v-toolbar-title>  
     </v-app-bar>
  </header>
</template>

<script>
  export default {
    name: 'TheHeader',

    data() {
      return {  
        dialog: false,       
      }
    },

    props: {
      showUnloadFileIcon: {
        type: Boolean,
        required: true        
      },    
    },
 
    methods: {
      rUSure() {
        this.dialog = true        
      },
    
      unloadHtmlFile(){
        this.dialog = false
        this.$emit('unload-html-file');
     }
    }
  }   
</script>