<template>
    <v-list-item>
        <v-list-item-icon>
            <v-icon color="primary">
                {{icon}}
            </v-icon>
        </v-list-item-icon>  
        <v-list-item-content>
            <v-list-item-title class="wrap-text"><b>{{title}}</b></v-list-item-title>
            <v-list-item-subtitle class="wrap-text">{{subtitle1}}</v-list-item-subtitle>                                  
            <v-list-item-subtitle class="wrap-text">{{subtitle2}}</v-list-item-subtitle>                                  
        </v-list-item-content>
    </v-list-item>  
</template>

<script>
export default {
    name: 'BaseListItem',
    
    props: {       
        icon: {
            type: String,            
        },
        title: {
            type: String,            
            required: true,
        },
        subtitle1: {
            type: String,                        
        },
        subtitle2: {
            type: String,                        
        },
    },    
}
</script>
<style scoped>
.wrap-text {
   white-space: normal;
}
</style>>