<template>
  <v-row>
    <v-col align="center">
      <v-btn 
        @click= "onClick"
        color = "primary" 
        large
        block         
        elevation="14">  
          {{btnText}}
      </v-btn> 
    </v-col>
  </v-row>
</template>



<script>
export default {
  name: 'BaseButtonLarge',

  props: {
    btnText: {
      type: String,
      default: 'weiter',           
    },   
  },
   
  data() {
    return {             
    }
  },

  methods: {
    onClick() {      
      this.$emit('btnClick', this.btnText);      
    },
  },
}
</script>