<template>
    <v-app>
      <v-banner
        v-if="deferredPrompt"
        color="info"
        dark
        class="text-left"
      >        
        Installieren Sie die PWA auf Ihrem Gerät Es wird kaum Speicherplatz benötigt und funktioniert auch offline!
        
        <template v-slot:actions>
          <v-btn text @click="dismiss">Dismiss</v-btn>
          <v-btn text @click="install">Install</v-btn>
        </template>
      </v-banner>

    <v-main>
    <TheHeader
      v-bind:showUnloadFileIcon="showUnloadFileIcon"
      @unload-html-file="unloadHtmlFile"/>  
      
     <v-container>      
      <router-view
        @click-install="install" 
        @file-content="setHtmlString"
        @file-meta="setMetaforFile"
        @click-native="handleTaskClickEvent"
        @new-note-element="handleNoteElement"
        @add-to-file="handleAddToFile"
        @reset-taskElemet="resetTaskElemet"     
        @toggle-visibility="doToggleVisible"
        @toggle-isunsupported-task-link-type="doToggleIsUnsupportedTaskLinkType"

        v-bind:htmlString="htmlString"
        v-bind:taskElement="taskElement"
        v-bind:noteElementResultString="noteElementResultString"
        
        v-bind:isUnsupportedTaskLinkType="isUnsupportedTaskLinkType"
        v-bind:isExpandClassAndMethod="isExpandClassAndMethod"
        v-bind:taskHasAnwser="taskHasAnwser"        
        v-bind:filename="filename"         

        v-bind:showRChooseFile="showRChooseFile"
        v-bind:showRDisplayFile="showRDisplayFile"
        v-bind:showWChooseType="showWChooseType"
        v-bind:showWResult="showWResult"
        v-bind:showWClassForm="showWClassForm"
        v-bind:showWLoopForm="showWLoopForm"
        v-bind:showWMethodForm="showWMethodForm"
        v-bind:showWConditionForm="showWConditionForm"
        v-bind:showWVariableForm="showWVariableForm"
        v-bind:showWNoteForm="showWNoteForm"
      />
    </v-container>                
    </v-main>
  </v-app>
</template>

<script>  
import TheHeader from './components/TheHeader.vue'

  export default {
    name: 'App',  

    //----------------------------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------VARS-------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------------------------------------------

    data(){
      return {       
        deferredPrompt: null, // for install PWA
        unloadAnswer: false, // if user wants to leave the page
        dialog: false, // toggle dialog for install PWA
        fileIsloaded: false, // toggle unload file btn on header / set on header and setHtmlString
        
        htmlString: '', // html string  main object 
        filename: '', // filename of the file
        noteElementResultString: '', // result string from noteElement (for ReaderDisplayFile.vue)
        noteElement: Object,  // noteElement main object
        taskElement: { // task element main object
          type: '',
          id: '',
          question: '',
          questtionHtml: '',
          anwserId: '',
          answer: '',   
          anwserHtml: '',          
          is5cnAnswer: false,  
          is5CodeAnswer: false,  
        },
        taskHasAnwser: false, // toggle task has anwser use in WizardChooseType
        ownHtmlattribute: 'data-5cn-id="975"',

        isUnsupportedTaskLinkType: false,  // true if taskElement.type = ACTION -> show dialog on ReaderDisplayFile.vue
        showNavButtomMenu: true,    // DEL me nav not longer in use   
        isExpandClassAndMethod: true, // true if class and method in MethodeChooseType are expanded    

        //-----NAVIGATION-----
        // toogelVisible Reader
        showRDisplayFile: false,
        showRChooseFile: true,

        // toogelVisible Wizard
        showWChooseType: true,
        showWResult: false,

        // Froms
        showWNoteForm : false,        
        showWClassForm: false,
        showWMethodForm: false,
        showWVariableForm: false,          
        showWLoopForm: false,          
        showWConditionForm: false,            
      }
    },

    created() {
      window.addEventListener("beforeinstallprompt", e => { //install prompt PWA non IOS
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });    
      window.addEventListener("appinstalled", () => {
        this.deferredPrompt = null;
      });
      
      window.addEventListener('beforeunload', this.handler) // leaving page
    },

    components: {
    TheHeader    
},

    computed: {      
      currentRouteName() {  //get the current route name
        return this.$route.name;  //this.$route.name is the name of the current route
      },
      showUnloadFileIcon() {
        if(this.currentRouteName === 'reader' && this.fileIsloaded === true) 
        {
          return true
        } 
        else 
        {
          return false
        }        
      },      
    },

    methods: {
      
    //----------------------------------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------APP--------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------------------------------------------
    //-----Install Prompt NOT IOS-----
      async dismiss() {
        this.deferredPrompt = null;
      },
      async install() {
        this.deferredPrompt.prompt();
      },
  
      //-----leaving page-----      
      handler(e) { //alert when beforeunload        
        e.returnValue = 'Are you sure you want to leave?'; // gen. Alert
        return 'Are you sure you want to leave?';
      },
      
      //----------------------------------------------------------------------------------------------------------------------------------------
      //--------------------------------------------------------------HtmlString----------------------------------------------------------------
      //----------------------------------------------------------------------------------------------------------------------------------------
        
      setMetaforFile(filename){
        this.filename = filename        
      },

      setHtmlString(rederResult){            
        this.htmlString = rederResult         
        this.doToggleVisible('showRChooseFile')
        this.doToggleVisible('showRDisplayFile')
        this.fileIsloaded = true // toggle unload file btn on header ON
      },

      unloadHtmlFile(){
        this.htmlString = ''
        this.resetNoteElemet()  
        this.resetTaskElemet()      
        this.doToggleVisible('showRChooseFile')
        this.doToggleVisible('showRDisplayFile')
        this.$router.push({ name: 'reader' }).catch(()=>{})   
        this.fileIsloaded = false // toggle unload file btn on header OFF
        //sett all WFrom to false
      },

      //----------------------------------------------------------------------------------------------------------------------------------------
      //--------------------------------------------------------------NoteElement---------------------------------------------------------------
      //----------------------------------------------------------------------------------------------------------------------------------------      
      handleNoteElement(noteElement){        
        this.noteElement = noteElement    
        this.builNoteElementResultString()        
      },

      builNoteElementResultString() {
        this.noteElementResultString = ''   // clean string
        for (var p in this.noteElement) { // loop through all properties of noteElement to create the noteElementResultString
          if (Object.prototype.hasOwnProperty.call(this.noteElement, p)) {  // check if property is not inherited                                      
              if(this.taskElement.is5cnAnswer){
                if(this.noteElement[p] === 'Notiz' || this.noteElement[p] === 'Beschreibung:'){                                
                  //do not add the strings
                }
                else{
                  this.noteElementResultString += this.noteElement[p] + '\n'
                }                 
              }
              else{
                this.noteElementResultString += this.noteElement[p] + '\n'
              }
          }            
        }
        this.noteElementResultString = this.noteElementResultString.trim() // remove last \n
      },      

      resetNoteElemet() {        
        this.noteElement = null
        this.noteElementResultString = ''        
      },

      //----------------------------------------------------------------------------------------------------------------------------------------
      //-------------------------------------------------------------TaskElement----------------------------------------------------------------
      //----------------------------------------------------------------------------------------------------------------------------------------
      resetTaskElemet() {        
        this.taskElement = {
          type: '',
          id: '',
          question: '',
          questtionHtml: '',
          anwserId: '',
          answer: '',
          anwserHtml: '',             
          is5cnAnswer: false,    
          is5CodeAnswer: false,
          }
        this.taskHasAnwser = false        
        this.resetNoteElemet()             
      },

      handleTaskClickEvent(e){        
        this.setTaskLinkVars(e)   
        this.supportedTaskLinkType()                
        this.routeTaskLink(e)        
      },

      supportedTaskLinkType(){
        if (this.taskElement.type == 'action') { // list all unsupported task link types
          this.isUnsupportedTaskLinkType = true
        }
        else {
          this.isUnsupportedTaskLinkType = false
        }
      },

      routeTaskLink(){  // Navigation and Tasklink routing
        if(this.taskElement.type === 'text' ){            
            this.$router.push({ name: 'wizard' }) 
            if(this.showWChooseType === true){
              this.doToggleVisible('showWChooseType')             
            }
            if(this.showWNoteForm === false){
              this.doToggleVisible('showWNoteForm')
            }            
        }
        if(this.taskElement.type === 'def' || this.taskElement.type === 'block' || this.taskElement.type === 'main'){   
          if(this.taskElement.type === 'def'){
            this.isExpandClassAndMethod = true
          }   
          else{
            this.isExpandClassAndMethod = false
          }          

          this.$router.push({ name: 'wizard' })                      

          if(this.showWChooseType === false){
            this.doToggleVisible('showWChooseType')             
          }
          if(this.showWNoteForm === true){
            this.doToggleVisible('showWNoteForm')
          }
        }   
      },

      setTaskLinkVars(e){  // set all infos from task link to taskElement
        if(this.isTaskLink(e)){                          
          this.taskElement.type = this.getType(e)  
          this.taskElement.id = this.getId(e)
          this.taskElement.question = this.getQuestion(e)
          this.taskElement.questtionHtml = this.getQuestionHtml(e)
          this.taskElement.anwserId = this.buildAnwserId(e)           
          this.taskHasAnwser =this.hasAnwser(e)
          this.taskElement.is5cnAnswer = this.getIs5cnAnswer()       
          this.taskElement.is5CodeAnswer = this.getIs5CodeAnswer(e) 

          if(this.taskHasAnwser){ 
            if(this.taskElement.is5CodeAnswer){ 
              if(this.taskElement.is5cnAnswer || this.taskElement.type === 'block'){
                this.taskElement.answer = this.getAnswer(e)  // just if there is an answer                
                this.taskElement.anwserHtml = this.getAnswerHtml(e) // just if there is an answer                
              }
            }
            else{
              this.taskElement.answer = this.getAnswer(e)  // just if there is an answer
              this.taskElement.anwserHtml = this.getAnswerHtml(e) // just if there is an answer              
            }
          }
        }
      },

      isTaskLink(e){
        return e.target.getAttribute('class') != null && e.target.getAttribute('class').startsWith("task-link")
      },

      getType(e) {
        let type =  e.target.getAttribute('class').split(' ')      
        return type[type.length - 1]
      },

      getId(e) {
        let id = e.target.getAttribute('class').split(' ')              
        return id[1]
      },

      getQuestion(e){    
        return e.target.parentElement.innerHTML.replace(/(<([^>]+)>)/gi, "");    // remove html tags    
      },
  
      getQuestionHtml(e){    
        return e.target.parentElement.innerHTML
      },

      buildAnwserId(){
        return this.taskElement.id.replace('s','e')        
      },

      getIs5CodeAnswer(e){        
        if(this.taskHasAnwser && this.taskElement.type === 'block' && !this.taskElement.is5cnAnswer){
          return true
        }
        else{
          if(e.target.getAttribute('data-href') != null){            
            return e.target.getAttribute('data-href').includes('.java')
          }
          else{
            return false
          }  
        }
      },

      getIs5cnAnswer(){                 
        if(this.taskElement.questtionHtml.includes(this.ownHtmlattribute)){
          return true
        }
        else{
          return false
        }        
      },

      hasAnwser(e){                       
        return e.target.getAttribute('data-href') != null
      },

      getAnswer(e){         
        if(this.hasAnwser(e) ){         
          if(this.taskElement.type === 'main' || this.taskElement.type === 'def' || this.taskElement.type === 'block'){
            let element = document.querySelector('[data-solution-id="' + this.taskElement.anwserId + '"]')    // nomale - ohne 5CODE antwort                        
            element = element.innerHTML.replace(/<br>/g, '\n')// replace all <br> with \n             
            element = element.replace(/(<([^>]+)>)/gi, "");            
            return element
          }
          if(this.taskElement.type === 'text'){  
            let element = document.getElementsByClassName('hilitesolution' + ' ' + this.taskElement.anwserId)[0].innerHTML   
            element = element.replace(/<br>/g, '\n')// replace all <br> with \n                        
            return element
           }        
        }
      },

      getAnswerHtml(e){
        if(this.hasAnwser(e) ) {     // in if:     && !this.taskElement.is5CodeAnswer
          if(this.taskElement.type === 'block'){                 
           return document.querySelector('[data-solution-id="' + this.taskElement.anwserId  + '"]').outerHTML;            
          }
          if(this.taskElement.type === 'main' || this.taskElement.type === 'def'){
            return document.querySelector('[data-solution-id="' + this.taskElement.anwserId  + '"]').outerHTML;    
          }
          if(this.taskElement.type === 'text'){  
            return document.getElementsByClassName('hilitesolution' + ' ' + this.taskElement.anwserId)[0].outerHTML         
          }    
        }
      },

      //-----Tasklink type not supported-----
      doToggleIsUnsupportedTaskLinkType(){        
        this.isUnsupportedTaskLinkType = !this.isUnsupportedTaskLinkType;     
        if(!this.isUnsupportedTaskLinkType){
           this.resetTaskElemet()            
        } 
      },


      //----------------------------------------------------------------------------------------------------------------------------------------
      //--------------------------------------------------Build html String & add to flow.html--------------------------------------------------
      //----------------------------------------------------------------------------------------------------------------------------------------
      handleAddToFile(localNoteResultString){   // builds all html strings and adds to flow.html
        this.noteElementResultString  = localNoteResultString // get last changes from noteElementResult

        
        if(this.taskElement.answer != null){  // convert back to html tags          
          this.taskElement.answer = this.taskElement.answer.replace(/\n/g, '<br>') //replace all \n with <br>                     
        }
        this.noteElementResultString = this.noteElementResultString.replace(/\n/g, '<br>')        

        if(this.taskElement.type === 'text'){       
          this.buildTextHtml()          
        }
        if(this.taskElement.type === 'def' || this.taskElement.type === 'main'){
          this.buildDefMainHtml()                    
        }
        if(this.taskElement.type === 'block'){
          this.buildBlockHtml()          
        }
        this.toggleAfterBuildResultHtml()  // to go reader
        this.resetTaskElemet() // set task to null
      },

      buildTextHtml(){        
        let toReplace = ''        
        let toReplaceWith = ''      
        let modOwnHtmlattribute = this.ownHtmlattribute.slice(0, -1) // remove last " from string        

        if(!this.taskHasAnwser){ // ---------no answer ---------          
          toReplace = '<p>' + this.taskElement.questtionHtml + '</p>'                     
          let newQuestion = toReplace.replace(this.taskElement.type, this.taskElement.type + '" data-href="#' +this.taskElement.anwserId + '" ' + modOwnHtmlattribute) // add data-href to question          
          let anwserHtml = '<p><span class="hilitesolution' + ' ' + this.taskElement.anwserId + '">' + this.noteElementResultString + '</span></p>' //build anwser html
          toReplaceWith = newQuestion + '\n' + anwserHtml
        }
        if(this.taskHasAnwser && this.taskElement.is5cnAnswer){ // ---------has 5 NOTES answer ---------        
          let question = '<p>' + this.taskElement.questtionHtml + '</p>\n'          
          let anwser = '<p>' + this.taskElement.anwserHtml + '</p>' 
          toReplace = question + anwser          
          let newAnwser = '<p>' + this.taskElement.anwserHtml.replace(this.taskElement.answer, this.noteElementResultString) + '</p>'// replace old answer with new (noteElementResultString)
          toReplaceWith = question + newAnwser
        }
        if(this.taskHasAnwser && !this.taskElement.is5cnAnswer){  // ---------5Code answer exists---------             
          let question = '<p>' + this.taskElement.questtionHtml + '</p>\n'              
          let anwser = '<p>' + this.taskElement.anwserHtml + '</p>'          
          toReplace = question + anwser          
          let striped = this.taskElement.anwserHtml          
          striped = striped.replace(/(<([^>]+)>)/gi, ""); // remove all html elements
          let newQuestion = question.replace(this.taskElement.anwserId, this.taskElement.anwserId + '" ' + modOwnHtmlattribute) // add data-href to question                
          let newAnwser = '<p>' + this.taskElement.anwserHtml.replace(striped, this.noteElementResultString) + '</p>'// replace old answer with new (noteElementResultString)
          toReplaceWith = newQuestion + newAnwser
        }                    
        this.htmlString = this.htmlString.replace(toReplace, toReplaceWith)
      },

      buildDefMainHtml(){                   
        let toReplace = ''        
        let toReplaceWith = ''   
        let modOwnHtmlattribute = this.ownHtmlattribute.slice(0, -1) // remove last " from string
        
        if(!this.taskHasAnwser && !this.taskElement.is5CodeAnswer){ // ---------no answer at all---------             
          toReplace = '<p>' + this.taskElement.questtionHtml + '</p>'                     
          let newQuestion = toReplace.replace(this.taskElement.type, this.taskElement.type + '" data-href="#' +this.taskElement.anwserId + '" ' + modOwnHtmlattribute) // add data-href to question          
          let anwser = '<div class="code segment" data-solution-id="' + this.taskElement.anwserId + '">' + this.noteElementResultString   + '</div>'//build anwser html 
          toReplaceWith = newQuestion + '\n' + anwser
        }
        if(this.taskHasAnwser && this.taskElement.is5cnAnswer){   // ---------5notes answer exists - no 5code --------             
          let newQuestion = '<p>' + this.taskElement.questtionHtml + '</p>\n'          
          let anwser = this.taskElement.anwserHtml          
          toReplace = newQuestion + anwser
          let newAnwser = this.taskElement.anwserHtml.replace(this.taskElement.answer, this.noteElementResultString)// replace answer with noteElementResultString
          toReplaceWith = newQuestion + newAnwser
        }
        if(this.taskHasAnwser && this.taskElement.is5CodeAnswer && !this.taskElement.is5cnAnswer){  // ---------empty 5code answer exists - no 5note --------            
          toReplace = '<p>' + this.taskElement.questtionHtml + '</p>'  
          let newQuestion = toReplace.replace(this.taskElement.type, this.taskElement.type + '" ' + modOwnHtmlattribute) // add data-href to question          
          let anwser = '<div class="code segment" data-solution-id="' + this.taskElement.anwserId + '">' + this.noteElementResultString   + '</div>'//build anwser html
          toReplaceWith = newQuestion + '\n' + anwser
        }                                   
        this.htmlString = this.htmlString.replace(toReplace, toReplaceWith)
      },    

      buildBlockHtml(){        
        let toReplace = ''        
        let toReplaceWith = ''  
        let modOwnHtmlattribute = this.ownHtmlattribute.slice(0, -1) // remove last " from string         
        let modFilename = this.filename.replace('.flow.html', '') // remove .flow.html from this.filename string
        
        if(!this.taskHasAnwser && !this.taskElement.is5CodeAnswer){ // ---------no answer---------                       
          toReplace = '<p>' + this.taskElement.questtionHtml + '</p>' 
          let newQuestion = toReplace.replace(this.taskElement.type, this.taskElement.type + '" data-href="#' +this.taskElement.anwserId + '" ' + modOwnHtmlattribute)  // add data-href to question          
          let anwser = '<div class="code segment" '+ 'data-class-name="'+ modFilename +'" data-solution-id="' + this.taskElement.anwserId + '"> ' + this.noteElementResultString + '</div>' //build anwser html
          toReplaceWith = newQuestion + '\n' + anwser
        }
        if(this.taskHasAnwser && !this.taskElement.is5CodeAnswer){   // ---------5notes & no 5code --------                  
          let question = '<p>' + this.taskElement.questtionHtml + '</p>\n'
          let anwser = this.taskElement.anwserHtml
          toReplace = question + anwser          
          anwser = anwser.substring(0, anwser.indexOf('>')) + '>\n' + this.noteElementResultString + '</div>' // replace answer with noteElementResultString
          toReplaceWith = question + anwser
        }         
        if(this.taskHasAnwser && this.taskElement.is5CodeAnswer){  // ---------5code --------
          let question = '<p>' + this.taskElement.questtionHtml + '</p>\n</div>\n'
          let anwser = this.taskElement.anwserHtml
          toReplace = question + anwser
          anwser = anwser.substring(0, anwser.indexOf('>')) + '>\n' + this.noteElementResultString + '</div>' // replace answer with noteElementResultString
          toReplaceWith = question + anwser
        }
        this.htmlString = this.htmlString.replace(toReplace, toReplaceWith)        
      },

      //----------------------------------------------------------------------------------------------------------------------------------------
      //-------------------------------------------------------------Navigation-----------------------------------------------------------------
      //----------------------------------------------------------------------------------------------------------------------------------------
      doToggleVisible(name){                
        this[name] = !this[name]   
      },

      toggleAfterBuildResultHtml(){
               // make own method in navigation  
        this.doToggleVisible('showWResult')

        if(this.taskElement.type === 'text'){            
          this.doToggleVisible('showWNoteForm')
        }
        if(this.taskElement.type === 'def'){
          this.doToggleVisible('showWChooseType')
        }        
        this.$router.push({ name: 'reader' })   
      },        
    },  
  } 
</script>
