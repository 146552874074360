<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Willkommen,</h2>
        <p>5code.note ist ein Prototyp zur mobilen Bearbeitung von 5code Aufgaben (*.flow.html). Für weitere
          Infomationen besuchen Sie die
          <a @click="$router.push({ name: 'help' }).catch(() => { })">Hilfe</a>.
        </p>
      </v-col>
    </v-row>
    <v-row class="mb-6">
      <v-col>
        <BaseButtonLarge :btnText="'Start'" @btnClick="onClick" />
      </v-col>
    </v-row>
    <h2>Ablauf</h2>
    <v-row>
      <v-col>
        <v-card>
          <v-list two-line>
            <BaseListItem icon="mdi-folder-open-outline" title="Datei auswählen"
              subtitle1="5code Aufgabendatei (*.flow.html)" />
            <v-divider />
            <BaseListItem icon="mdi-fingerprint" title="Aufgabe anklicken"
              subtitle1="Nicht alle Aufgabentypen werden aktuell unterstützt" />
            <v-divider />
            <BaseListItem icon="mdi-brain" title="Aufgabe beantworten" subtitle1="Antwort erstellen oder editieren" />
            <v-divider />
            <BaseListItem icon="mdi-shield-check-outline" title="Antwort kontrollieren"
              subtitle1="Antwort kann verändert oder erweitert werden" />
            <v-divider />
            <BaseListItem icon="mdi-file-code-outline" title="Antwort einfügen"
              subtitle1="Antwort in die Aufgabenstellung einfügen" />
            <v-divider />
            <BaseListItem icon="mdi-content-save" title="Speichern" subtitle1="Datei mit Antwort(en) speichern" />
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BaseButtonLarge :btnText="'Start'" @btnClick="onClick" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseButtonLarge from "@/components/BaseButtonLarge.vue"
import BaseListItem from "@/components/BaseListItem.vue"

export default {
  name: 'HomeView',

  data() {
    return {
    }
  },

  components: {
    BaseButtonLarge,
    BaseListItem
  },

  methods: {
    onClick() {
      this.$router.push({ name: 'reader' })
    },
  },
}
</script>

<style scoped>
.wrap-text {
  white-space: normal;
}

.link {
  color: #1976d2;
}
</style>
